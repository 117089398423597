@use '../../../../../../../../styles';

.deliver__details-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(222, 229, 224, 1);
  margin-bottom: 20px;
  padding-bottom: 20px;

  .deliver__details-row-label {
    color: styles.$variables-tailwind-black;
    @include styles.mixins-style-font-format(16px, 24px, 700);
  }

  
  .deliver__details-row-value {
    color: styles.$variables-tailwind-black;
    white-space: normal;
    word-break: break-word;
    text-align: right;
    @include styles.mixins-style-font-format(16px, 24px, 400);
  }
}