@use '../../styles';

.input {
  @include styles.mixins-style-width-height(100%, auto);

  .ant-form-item-required {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;

    &::before {
      margin-left: 4px;
      display: block !important;
    }
  }

  .ant-form-item-label > label {
    @include styles.mixins-style-font-format(16px, 24px, 500);
    letter-spacing: 0px;
    text-align: left;
    color: styles.$variables-tailwind-black;
  }

  .ant-input:focus {
    border-color: styles.$variables-tailwind-gray-2;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
    box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
  }

  .ant-form-item-control {

    .ant-radio {
      @include styles.mixins-style-font-format(16px, 24px, 400);
      color: rgba(15, 23, 42, 1);
    }

    .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
      border-color: styles.$variables-tailwind-green !important;
      background-color: rgba(241, 255, 242, 1) !important;
    }

    .ant-radio-inner::after {
      background-color: styles.$variables-tailwind-green; 
    }

    .PhoneInput--focus {
      input:required:invalid {
        border-color: red !important;
      }
    }

    .PhoneInput {

  
      .PhoneInputCountry {
        display: none !important;
      }

      input {
        border: 1px solid styles.$variables-tailwind-gray !important;
      }
    
      .PhoneInputInput {
        font-family: "Inter" !important;
        font-size: 16px !important; 
        font-weight: 400;
        line-height: 24px !important;
        letter-spacing: 0px;
        text-align: left;
        color: styles.$variables-tailwind-black !important;
        height: 46px !important;
        border-radius: 6px !important;

        &::placeholder {
          color: styles.$variables-tailwind-gray;
        }

        &:focus {
          border-color: styles.$variables-tailwind-gray-2;
          outline: 0;
          -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
          box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
        }

      }
     

    }

    textarea {
      height: auto !important;
      @include styles.mixins-style-font-format(16px, 24px, 400);
      color: styles.$variables-tailwind-black;
      border-radius: 6px;
      border: 1px solid styles.$variables-tailwind-gray;
    }
    
    .ant-input {
      @include styles.mixins-style-font-format(16px, 24px, 400);
      letter-spacing: 0px;
      text-align: left;
      color: styles.$variables-tailwind-black;
      height: 46px;
      border-radius: 6px;
      border: 1px solid styles.$variables-tailwind-gray;
    }

    .ant-picker {
      width: auto !important;
      @include styles.mixins-style-font-format(16px, 24px, 400);
      letter-spacing: 0px;
      text-align: left;
      color: styles.$variables-tailwind-black;
      height: 46px;
      border-radius: 6px;
      border: 1px solid styles.$variables-tailwind-gray;

      .ant-picker-input {
        @include styles.mixins-style-font-format(16px, 24px, 400);

      }
    }


    .ant-input-number {
      width: 100% !important;
    }

    .ant-input-number-input {
      @include styles.mixins-style-font-format(16px, 24px, 400);
      letter-spacing: 0px;
      text-align: left;
      color: styles.$variables-tailwind-black;
      height: 46px;
      border-radius: 6px;
      border: 1px solid styles.$variables-tailwind-gray;
    }

    .ant-input-affix-wrapper,
    .ant-input-password {
      @include styles.mixins-style-font-format(16px, 24px, 400);
      letter-spacing: 0px;
      text-align: left;
      color: styles.$variables-tailwind-black;
      height: 46px;
      border-radius: 6px;
      border: 1px solid styles.$variables-tailwind-gray;

      .ant-input {
        border: none !important;
        height: auto;
        &:focus {
          border-color: styles.$variables-tailwind-green !important;
          outline: 0;
          -webkit-box-shadow: 0 0 0 2px rgba(28, 120, 52, 0.2);
          box-shadow: 0 0 0 2px rgba(28, 120, 52, 0.2);
        }
      }
    }
  }

  .ant-upload-list-item-actions {

    .ant-btn {
      background: none !important;
    } 
  }

  .ant-upload-wrapper.ant-upload-picture-card-wrapper {
    height: 154px !important;

    .ant-upload-list.ant-upload-list-picture-card {
      width: 304px !important;
      border-radius: 6px !important;
      border: 1px dashed styles.$variables-tailwind-green !important;
      background-color: styles.$variables-tailwind-gray-4 !important;
      height: 100% !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      @media (max-width: 393px) {
        width: 100% !important;
        max-width: 304px!important;
        
      }
      .ant-upload-list-item-container {
        width: 100% !important;
        height: 100% !important;
        margin: 0 !important;

        .ant-upload-list-item {
          border: none !important;
        }

        .ant-tooltip  {
          display: none !important;
        }
      }

      .ant-upload.ant-upload-select {
        width: 100%;
        height: 100%;
        margin: 0px;

        .ant-upload {
          align-items: start !important;
        }
      }
    }
  }

  .ant-switch {
    &-checked {
      .ant-switch-inner {
        background-color: styles.$variables-tailwind-green;
      }
    }
    &-inner {
      background-color: styles.$variables-tailwind-gray-3;
    }
  }

  .ant-select {
    height: 46px;
    border-radius: 6px;
    border: 1px solid styles.$variables-tailwind-gray;

    .ant-select-selector {
      @include styles.mixins-style-font-format(16px, 24px, 400);
      letter-spacing: 0px;
      color: styles.$variables-tailwind-black;
      height: 100% !important;
      border: none !important;
      align-items: center;

      &:focus {
        border: none !important;
      }
    }

    &:focus {
      border-color: styles.$variables-tailwind-green;
      outline: 0;
      -webkit-box-shadow: 0 0 0 2px rgba(28, 120, 52, 0.2);
      box-shadow: 0 0 0 2px rgba(28, 120, 52, 0.2);
    }
   
  }

  &.switch-reverse {
      .ant-row {
        display: flex !important;
        flex-direction: row-reverse !important;
        align-items: center !important;
        width: fit-content !important;

        .ant-form-item-label {
          margin-left: 10px;
        }
      }
  }

}
