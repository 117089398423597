@use '../../../styles';

.login__container {
  @include styles.mixins-style-width-height(92%, 100%);
  max-width: 420px;
  flex-grow: 1;
  @include styles.mixins-style-flex-column;
  justify-content: center;
  margin: 1rem;
  
  .login__form {

    &-heading {
      @include styles.mixins-style-font-format(24px, normal, 400, none, .03em);
      text-align: center;
      color: styles.$variables-gray;
      margin-bottom: 20px;
    }

    .login__btn {
      @include styles.mixins-style-font-format(11px, 2px, normal, uppercase, 2px);
      background-color: styles.$variables-green;
      width: 100%;
      border-radius: 25px;
      position: relative;
      overflow: hidden;
      padding: 0;
    }
    
    .login__btn::after {
      content: '';
      position: absolute;
      top: 0;
      left: 100%;
      width: 100%;
      height: 100%;
      background-color: transparent;
      transition: left 0.3s ease-in-out;
    }
    
    .login__btn:hover::after {
      left: 0;
      background-color: styles.$variables-white;
    }
    
    .login__btn .text {
      position: relative;
      z-index: 1;
      transition: color 0.3s ease-in-out;
    }
    
    .login__btn:hover .text {
      color: styles.$variables-green;
    }

     &-options {
      @include styles.mixins-style-flex-row-space-between;
      margin-bottom: 8px;

      .option {
        color: styles.$variables-green;
        font-weight: 600;
        font-family: "Jost", sans-serif;
        text-decoration: none;
      }
     }
  }
}
