@use '../../../../../../../../styles';

.user__component {
  width: 100%;

  &-header {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(33, 43, 54, 1);
    margin-bottom: 40px;
  }

  .user__details-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;

    &-avatar {
      min-width: 98px;
      max-width: 98px;
      min-height: 100px;      
      max-height: 100px;      
      border-radius: 16px;
      margin-right: 30px;
    }

    .user__details {
      .name {
        color: styles.$variables-tailwind-green;
        @include styles.mixins-style-font-format(16px, 24px, 500);
      }
  
      .content-with__icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        
        svg {
          margin-right: 10px;
          width: 16px;
          height: 16px;
          
          path {
            fill: styles.$variables-tailwind-black;
          }
        }
  
        .content-data {
          color: styles.$variables-tailwind-black;
          @include styles.mixins-style-font-format(16px, 24px, 400);
        }
      }
    }
  }

}

    
@media (max-width: 786px) {
  .user__component {
    margin-bottom: 40px;
  }
}

@media (max-width: 440px) {
  .user__component {
    .user__details-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &-avatar {
        margin-bottom: 20px;
      }
    }
  }
}

