@use  '../../../../../../../../../../styles';

.ant-modal-wrap {

  .reject__order-modal {
  
    .reject__order-actions {
      padding-top: 20px;

      .ant-form-item-required {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;

        &::before {
          margin-left: 4px;
          display: block !important;
        }
      }

      .reject__warning-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 30px;

        svg {
          min-width: 22.75px;
          min-height: 22.75px;
        }

        .reject__warning {
          margin-left: 5px;
          color: rgba(0, 0, 0, 1);
          @include styles.mixins-style-font-format(16px, 19px, 400);
        }
      }
      .reject-btn {
        width: 114px;
        height: 40px;
        border-radius: 6px;
        background: rgba(234, 67, 53, 1) !important;
        color: rgba(255, 255, 255, 1) !important;
        @include styles.mixins-style-font-format(14px, 24px, 500);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        float: right;
      }
    }

    &.ant-modal  {  
      width: 515px !important;  

      .ant-modal-content {
        border-radius: 20px;
        height: 386px !important;

        .ant-modal-header {

          .ant-modal-title {
            @include styles.mixins-style-font-format(16px, 24px, 500);
            color:  styles.$variables-tailwind-black;
            padding-bottom: 10px;
            border-bottom: 1px solid styles.$variables-tailwind-gray-5;
          }
        }

        .ant-modal-body {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
