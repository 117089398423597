@use '../../../../../../styles';

.order__box-view {
  display: none;
}

@media (max-width: 1359px) {
  .order__box-view {
    display: flex;
    flex-direction: column;
  
      .order__box-container {
        width: 100%;
        min-height: 126px;
        padding: 16px;
        border-radius: 8px;
        border-top: 1px solid rgba(222, 229, 224, 1);
        background: white;
        display: flex;
        flex-direction: column;
        
        .container__lower-part {
          margin-top: 15px;
  
          .order__divider-container {
            display: flex;
            flex-direction: row;
            align-items: center;
  
            &.light {
              .order__detail-content {
                color: rgba(0, 0, 0, 1);
                @include styles.mixins-style-font-format(14px, 20px, 500);
                &:nth-child(even) {
                  padding-left: 5px;
                  margin-left: 5px;
                  border-left: 1px solid rgba(222, 229, 224, 1);
                }
              }
            }
          }
        }
  
        .container__upper-part {
          margin-bottom: 15px;
          display: flex;
          flex-direction: row;
          align-items: center;
  
          .upper__order-detail {
            margin-left: 15px;
  
            .customer__name {
              color: rgba(0, 0, 0, 1);
              @include styles.mixins-style-font-format(16px, 20px, 600);
            }
          }
  
          .table__item-avatar {
            width: 42px !important;
            height: 42px !important;
          }
  
  
          .order__divider-container {
            display: flex;
            flex-direction: row;
            align-items: center;
  
  
            &.dark {
              .order__detail-content {
                color: rgba(0, 0, 0, 1);
                @include styles.mixins-style-font-format(14px, 20px, 500);
                &:nth-child(even) {
                  padding-left: 5px;
                  margin-left: 5px;
                  border-left: 1px solid rgba(131, 134, 136, 1);
                }
              }
            }
  
            &.light {
              .order__detail-content {
                color: rgba(0, 0, 0, 1);
                @include styles.mixins-style-font-format(14px, 20px, 500);
                &:nth-child(even) {
                  padding-left: 5px;
                  margin-left: 5px;
                  border-left: 1px solid rgba(222, 229, 224, 1);
                }
              }
            }
          }
        }
  
        .container__lower-part {
          width: 100%;
          border-top: 1px solid rgba(222, 229, 224, 1);
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }
      }
      .custom-pagination {
        padding-left: 0;
        padding-right: 0;
      }
  }
}

@media (max-width: 440px) {
  .order__box-view {

    .order__box-container {
      
      .container__lower-part {
        margin-top: 15px;

        .order__divider-container {
          display: flex;
          flex-direction: row;
          align-items: center;

          &.light {
            .order__detail-content {
              color: rgba(0, 0, 0, 1);
              @include styles.mixins-style-font-format(12px, 20px, 500);
              padding-left: 5px;
              margin-left: 5px;
              border-left: 1px solid rgba(222, 229, 224, 1);
            }
          }
        }
      }

      .container__upper-part {
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .upper__order-detail {
          margin-left: 0;

          .customer__name {
            margin-top: 5px;
          }
        }

        .table__item-avatar {
          width: 42px !important;
          height: 42px !important;
        }


        .order__divider-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          &.dark {
            .order__detail-content {
              color: rgba(0, 0, 0, 1);
              @include styles.mixins-style-font-format(12px, 20px, 500);
              &:nth-child(even) {
                padding-left: 0;
                margin-left: 0;
                border-left: none;
              }
            }
          }
        }
      }

      .container__lower-part {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

  }
}