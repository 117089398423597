@use '../../../../styles';

.order__detail {

  max-width: 1151px;
  
  .order__delivery-detail__mobile {
    display: none;
  }

  .print__order-btn {
    max-width: 91px !important;
    height: 40px !important;
    @include styles.mixins-style-font-format(14px, 24px, 500);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &-back__btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .print__order-btn {
      display: none;
    }
  }

  .order__detail-body {
    width: 100%;
    padding: 20px;
    background: white;
    display: flex;
    flex-direction: row;

    .order__detail-left {
      width: 100%;
      max-width: 702px;
      
      .data__column-row {

        &:nth-child(n+2) {
          margin-top: 15px;
        }
      }
    }

    .order__detail-right {
      margin-left: 15px;
      width: 100%;
      max-width: 389px;
      height: 100%;
    }

    
  }
}

@media (max-width: 1235px) {
.order__detail {

  .order__detail-body {
    display: flex;
    flex-direction: column;
    
    .order__detail-right { 
      margin: 0;
    }
  }
}
}

@media (max-width: 660px) {
  .order__detail {

    .order__delivery-detail__mobile {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      .view-order-timeline {
        @include styles.mixins-style-font-format(14px, 24px, 500);
        color: rgba(0, 0, 0, 1);
        cursor: pointer;

        .ant-btn {
          text-decoration: underline;
        }
      }
    }

    .order__detail-right { 
      display: none;
    }
   
    &-back__btn {
      margin-bottom: 20px;
  
      .ant-breadcrumb-link {
        margin-bottom: 0 !important;

        .title {
          margin-bottom: 0 !important;
          font-size: 20px !important;
          margin-right: 5px;
        }
      }
  
      .print__order-btn {
        max-width: 91px !important;
        height: 40px !important;
        @include styles.mixins-style-font-format(14px, 24px, 500);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
  }
 
  .data__column-row  {
    width: 100% !important;
    display: flex;
    flex-direction: column !important;
    padding: 0;

    .ant-col {
      max-width: 100% !important;
    }

    .order__detail-data__column  {
      border-bottom: 1px solid rgba(222, 229, 224, 1) !important;
    }

    &:last-child{
      .ant-col {
        &:last-child {
          .order__detail-data__column  {
            border: none !important;
            padding-bottom: 0px;
          }
        }
      }
    }
  }
}