@use '../../../../../../../../styles';

.configuration__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  height: 120px;
  border-radius: 7px 7px 0px 0px;
  border: 1px solid styles.$variables-tailwind-gray-5;
  background-color: #F9FDF4;

  .config__details {
    display: flex;
    flex-direction: row;
    align-items: center;

    &-avatar {
      min-width: 85px;
      min-height: 85px;
      max-width: 85px;
      max-height: 85px;
      border-radius: 50%;
    }

    .details__container {
      display: flex;
      flex-direction: column;
      margin-left: 40px;
      max-width: 243px;

      .store__name {
        @include styles.mixins-style-font-format(17px, 20px, 600);
        color: styles.$variables-tailwind-black-2;
      }

      .store__address {
        @include styles.mixins-style-font-format(13px, 21px, 500);
        color: styles.$variables-tailwind-black-2;
      }
    }
  }

  .config__actions {
    display: flex;
    flex-direction: row;
    align-items: center;

    .activate {
      color: rgb(76, 128, 69) !important;
    }

    .ant-btn {
      width: 104px;
      height: 44px;
      border-radius: 6px;
      background: styles.$variables-tailwind-gray-7;
      border: 1px solid styles.$variables-tailwind-gray-5;
      @include styles.mixins-style-font-format(16px, 21px, 500);
      color: rgba(0, 0, 0, 1);
      display: flex;
      flex-direction: row;
      align-items: center;

      &:nth-child(2) {
        margin-left: 10px;
        color: rgba(198, 0, 0, 1);
      }
    }
  }
}

@media (max-width: 768px) {
 .configuration__header {
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    min-height: 120px;

    .config__details {
      margin-top: 15px;
      flex-direction: column;
      align-items: left !important;
    
    
      .details__container {
        margin-left: 0;
        margin-top: 15px;
      }
    }
    .config__actions {
      margin-top: 15px;
      margin-bottom: 15px;
    }
 }
}