@use '../../../../../../../../../../styles';

.submit-button {
  &:not(:disabled) {
    background-color: styles.$variables-green;
    border-color: styles.$variables-green;
    color: styles.$variables-white;
    &:hover {
      background-color: styles.$variables-green;
      border-color: styles.$variables-green !important;
      color: styles.$variables-white !important;
      opacity: 0.8;
    }
  }

  &:disabled {
    background-color: styles.$variables-gray;
    border-color: styles.$variables-gray;
    color: styles.$variables-black;
  }
}
