.order-accounting_table--custom-component {
  height: 100%;
  width: 150%;

  .ant-picker {
    height: 100%;
  }

  .ant-select-selector {
    overflow: hidden;
  }
}
