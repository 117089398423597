.catalog__list {
  overflow-y: scroll;
  margin-top: 16px;

  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: transparent !important;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-track {
    background-color: transparent !important;
  }
}