.config__settings {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 572px;

  .ant-btn-primary {
    margin-top: 1rem;
  }

  &-header {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(33, 43, 54, 1);
    margin-bottom: 1rem;
  }

  &-section {
    border-style: solid;
    border-width: 2px;
    padding: 1rem;
    margin-bottom: 2rem;
  }

  .ant-input-number {
    max-width: 200px !important;
  }
}