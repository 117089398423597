@use '../../../../../../styles';

.order__timeline {
  width: 100%;
  max-width: 389px;
  height: auto;
  padding: 16px;
  border: 1px solid rgba(222, 229, 224, 1);

  &-heading {
    color: styles.$variables-tailwind-black;
    @include styles.mixins-style-font-format(16px, 20px, 700);
    margin-bottom: 15px;
  }

  .timelines {


    &:last-child {
      .timeline__connector {
        visibility: hidden;
      }
    }
  }
}

@media (max-width: 660px) {
  .order__timeline {
    border: none;
    padding: 8px;
  }
}