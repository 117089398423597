.outlet__table--custom-component {
  min-width: 150px;

  .ant-picker {
    height: 100%;
  }

  .ant-select-selector {
    overflow: hidden;
  }
}
