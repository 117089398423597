.mob__sidebar {

  @media (min-width: 1024px) {
      display: none;
  }


 .ant-drawer-body {
    padding: 0 !important;
  }
}
