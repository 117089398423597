@use '../../../../../..//styles';

.add__user_select {
  @include styles.mixins-style-flex-column;

  &-content {
    max-width: 572px;
    position: relative;
    
  }
}

