
.category-modal {
  .ant-modal {
    width: 580px !important;
    max-width: 90vw;
  }
}

.category_modal_form {
  .ant-upload-wrapper.ant-upload-picture-card-wrapper {
    .ant-upload-list.ant-upload-list-picture-card {
      width: 100% !important;
    }
  }
}
