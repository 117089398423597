@use '../../../../styles';

.edit-profile-container {
  padding: 2rem 4rem;
  max-width: 75vw;

  .disable_email {
    .input {

      .ant-input-affix-wrapper {
        background: none !important;
        border: none !important;
        cursor: default;

        .ant-input.ant-input-disabled {
          cursor: default !important;
        }
      }
    }
  }
  
  .input_row {
    .input {
      &:nth-child(2) {
        margin-left: 20px !important;
      }

      @media (max-width: 1024px) {
        &:nth-child(2) {
          margin-left: 0 !important;
        }
      }
    };
  }

  .save-profile-button {
    @include styles.mixins-style-font-format(16px, 24px, 500);
    letter-spacing: 0px;
    color: styles.$variables-tailwind-green-2;
    border: none !important;

    &:hover, &:focus {
    color: styles.$variables-tailwind-green-4 !important;
    }
  }

  .ant-btn {
    width: 300px;
    height: 48px;
    border-radius: 6px;
  }
}
