@use '../../styles';

.public_pages {

  .logo__wrap {
    width: 100%;
    padding: 10px;
    @include styles.mixins-style-flex-column-center;
    
    .logo__sub-wrap {
      max-width: 1440px;
      width: 96%;
   
      img {
        width: 180px;
      }
    }

    @media (min-width: 1430px) {
      margin-left: 1.5rem!important;
    }

    @media (min-width: 1200px) {
      margin-left: 1.5rem!important;
    }

    @media (min-width: 992px) {
      margin-left: 1.5rem!important;
    }
    
    @media (min-width: 768px) {
      margin-left: 1rem!important;
    }

    @media(min-width: 576px) {
      margin-left: 1rem!important;
    }
  }

  .container {
    width: 100%;
    margin: 2rem auto;
    padding: 0 1rem;
    font-family: Roboto, sans-serif !important;

    .main-top-heading {
      margin-bottom: 2rem;
      font-size: 36px;
      color: #222;
      text-transform: uppercase;
      font-weight: 700;
      line-height: 1;
      letter-spacing: .02em;
    }

    .sub-heading {
      @include styles.mixins-style-font-format(24px, normal, 400, capitalize);
      text-transform: uppercase;
      margin: 1rem 0;
    }

    .section-heading {
      font-size: 24px;
      font-weight: 400;
      margin: 0.75rem 0;
    }

    p {
      line-height: 22px;
      font-size: 16px;
      margin-bottom: 1rem !important;
    }

    @media (min-width: 1430px) {
      max-width: 1400px!important;
    }

    @media (min-width: 1200px) {
      max-width: 1400px!important;
    }

    @media (min-width: 992px) {
      max-width: 960!important;
    }
    
    @media (min-width: 768px) {
      max-width: 720!important;
    }

    @media(min-width: 576px) {
      max-width: 540!important;
    }
  }
}
