@use '../../../../../../../../styles';

.hover__status {

  .actions {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    &_success-btn {
      min-width: 68px;
      max-width: 118px;
      width: auto;
      cursor: pointer;
      height: 32px;
      border-radius: 6px;
      margin-right: 6px;
      background:  rgba(95, 157, 44, 1) !important;
      color:  rgba(255, 255, 255, 1) !important;
      border: none !important;
      @include styles.mixins-style-font-format(14px, 24px, 500);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:focus, &:hover {
        background:  rgba(95, 157, 44, 1) !important;
        color:  rgba(255, 255, 255, 1) !important;
      }
    }

    &_danger-btn {
      width: 68px;
      height: 32px;
      border-radius: 6px;
      background:  rgba(234, 67, 53, 1) !important;
      color:  rgba(255, 255, 255, 1) !important;
      border: none !important;
      @include styles.mixins-style-font-format(14px, 24px, 500);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:focus, &:hover {
        background:  rgba(234, 67, 53, 1) !important;
        color:  rgba(255, 255, 255, 1) !important;
      }
    }

    &_picked_up_by_customer_success-btn {
      min-width: 68px;
      max-width: 118px;
      width: auto;
      cursor: pointer;
      height: 32px;
      border-radius: 6px;
      margin-right: 6px;
      background:  rgba(95, 157, 44, 1) !important;
      color:  rgba(255, 255, 255, 1) !important;
      border: none !important;
      @include styles.mixins-style-font-format(14px, 24px, 500);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:focus, &:hover {
        background:  rgba(95, 157, 44, 1) !important;
        color:  rgba(255, 255, 255, 1) !important;
      }
    }

    &_picked_up_by_customer_danger-btn {
      min-width: 68px;
      max-width: 118px;
      height: 32px;
      border-radius: 6px;
      background:  rgba(234, 67, 53, 1) !important;
      color:  rgba(255, 255, 255, 1) !important;
      border: none !important;
      @include styles.mixins-style-font-format(14px, 24px, 500);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:focus, &:hover {
        background:  rgba(234, 67, 53, 1) !important;
        color:  rgba(255, 255, 255, 1) !important;
      }
    }
  }

  .order__status {
    
    &-accepted, &-delivered {
      width: auto;
      cursor: pointer;
      height: 24px;
      padding: 2px 10px 2px 10px;
      border-radius: 10px;
      background-color:styles.$variables-status-accepted-light;
      color:styles.$variables-status-accepted;
      @include styles.mixins-style-font-format(12px, 18px, 400, capitalize);
      text-align: center;
      max-width: 128px !important;
    }
  
    &-pending {
      width: auto;
      cursor: pointer;
      height: 24px;
      padding: 2px 10px 2px 10px;
      border-radius: 10px;
      background-color:styles.$variables-status-pending-light;
      color:styles.$variables-status-pending;
      @include styles.mixins-style-font-format(12px, 18px, 400, capitalize);
      text-align: center;
      max-width: 128px !important;
    }

    &-rejected {
      width: auto;
      cursor: pointer;
      // height: 24px;
      padding: 2px 10px 2px 10px;
      border-radius: 10px;
      background-color:styles.$variables-status-rejected-light;
      color:styles.$variables-status-rejected;
      @include styles.mixins-style-font-format(12px, 18px, 400, capitalize);
      text-align: center;
      max-width: 128px !important;
    }

    &-driver_requested {
      width: auto;
      cursor: pointer;
      height: 24px;
      padding: 2px 10px 2px 10px;
      border-radius: 10px;
      background-color: styles.$variables-status-driver_requested-light;
      color:styles.$variables-status-driver_requested;
      @include styles.mixins-style-font-format(12px, 18px, 400, capitalize);
      text-align: center;
      max-width: 128px !important;
    }

    &-waiting_pickup {
      width: auto;
      cursor: pointer;
      height: 24px;
      padding: 2px 10px 2px 10px;
      border-radius: 10px;
      background-color:styles.$variables-status-waiting_pickup-light;
      color:styles.$variables-status-waiting_pickup;
      @include styles.mixins-style-font-format(12px, 18px, 400, capitalize);
      text-align: center;
      max-width: 128px !important;
    }
    
    &-driver_assigned {
      width: auto;
      cursor: pointer;
      height: 24px;
      padding: 2px 10px 2px 10px;
      border-radius: 10px;
      background-color:styles.$variables-status-driver_assigned-light;
      color:styles.$variables-status-driver_assigned;
      @include styles.mixins-style-font-format(12px, 18px, 400, capitalize);
      text-align: center;
      max-width: 128px !important;
    }

    &-driver_arrived {
      width: auto;
      cursor: pointer;
      height: 24px;
      padding: 2px 10px 2px 10px;
      border-radius: 10px;
      background-color:styles.$variables-status-driver_arrived-light;
      color:styles.$variables-status-driver_arrived;
      @include styles.mixins-style-font-format(12px, 18px, 400, capitalize);
      text-align: center;
      max-width: 128px !important;
    }
    
    &-driver_in_route {
      width: auto;
      cursor: pointer;
      height: 24px;
      padding: 2px 10px 2px 10px;
      border-radius: 10px;
      background-color:styles.$variables-status-driver_in_route-light;
      color:styles.$variables-status-driver_in_route;
      @include styles.mixins-style-font-format(12px, 18px, 400, capitalize);
      text-align: center;
      max-width: 128px !important;
    }
  }
}