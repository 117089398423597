@use "../../../styles";

.upload-image {
  border: none !important;
  
  &:hover, &:focus {
    border: none !important;
    text-decoration: none !important;
    
  }

  &__content {
    margin-top: 15px;

    p {
      @include styles.mixins-style-font-format(10px, 20px, 400);
      letter-spacing: 0px;
      text-align: center;
      span {
        color: styles.$variables-tailwind-green;

      }
    }
  }
}