@use  '../../../../../../styles';

.ant-modal-wrap {

  .import__store-modal {

    .import__store-modal__error-message {
      color: red;
      margin-bottom: 0.75rem;
    }

    .import__sample-file {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 20px;
      cursor: pointer;

      .sample__file-text {
        @include styles.mixins-style-font-format(16px, 24px, 500);
        color:  styles.$variables-tailwind-black;
        margin-left: 10px;
        border-bottom: 1px solid styles.$variables-tailwind-black;
      }
    }

    .ant-upload-wrapper.ant-upload-picture-card-wrapper {
      height: 252px !important;
  
      .ant-upload-list.ant-upload-list-picture-card {
          width: 430px!important;

          @media (max-width: 520px) {
            width: 100% !important;
            max-width: 460px!important;
          }

          .ant-upload {
            width: 100%;
            height: 100%;

              .ant-upload {
                display: flex;
                flex-direction: column;
                align-items: center !important;
                justify-content: center;
              }

            .upload-image__content {
              margin-top: 0;

              svg {
                margin-bottom: 20px;
              }
            }
          }
        }
    }

    &.ant-modal  {  
      width: 479px !important;  

      .ant-modal-content {
        border-radius: 20px;
        // height: 467px !important;

        .ant-modal-header {

          .ant-modal-title {
            @include styles.mixins-style-font-format(16px, 24px, 500);
            color:  styles.$variables-tailwind-black;
            padding-bottom: 10px;
            border-bottom: 1px solid styles.$variables-tailwind-gray-5;
          }
        }

        .ant-modal-body {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}