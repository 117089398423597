.store__configuration {


  &-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

  }
}

    
@media (max-width: 786px) {
  .store__configuration {

    &-body {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}