@use  '../../styles';

.ant-modal-wrap {

  .add__address {

    &.ant-modal  {  
      width: 870px !important;  
      height: 560px;

      .ant-modal-content {
        border-radius: 20px;
        height: auto;

        .location__heading {
          color: rgba(0, 0, 0, 1);
          margin-top: 40px;
          margin-bottom: 10px;
          @include styles.mixins-style-font-format(20px, 20px, 800);
        }

        .location__value {
          color: rgba(144, 145, 139, 1);
          @include styles.mixins-style-font-format(16px, 20px, 400);
        }

        .ant-btn {
          margin-top: 20px;
          max-width: 135px;
        }

        .ant-modal-header {

          .ant-modal-title {
            @include styles.mixins-style-font-format(16px, 24px, 500);
            color:  styles.$variables-tailwind-black;
            padding-bottom: 10px;
            border-bottom: 1px solid styles.$variables-tailwind-gray-5;
          }
        }

        .ant-modal-body {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
