@use '../../../../../../styles';

.order__summary {
  width: 100%;
  height: auto;
  border: 1px solid rgba(222, 229, 224, 1);
  margin-bottom: 20px;

  .order__summary-table {
    padding: 20px;
    padding-bottom: 0;

    .table {
      width: 100%;
      
      .table-body {
        box-shadow: none !important;
    
        .ant-table {
          border: 1px solid rgba(222, 229, 224, 1) !important; 

          .table__content {
            color: styles.$variables-tailwind-black;
            @include styles.mixins-style-font-format(16px, 24px, 500);
          }
        }
      }
    }
  }

  .order__summary-table__mobile {
    display: none;
  }

  .order__summary-store {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 20px;
    padding-bottom: 0;

    .store__details {
      display: flex;
      flex-direction: row;
      align-items: center;
  
      &-avatar {
        margin-right: 10px;
        min-width: 64px;
        min-height: 64px;
        border-radius: 8px;
      }

      .details__container {
        display: flex;
        flex-direction: column;

        .store__name {
          color: styles.$variables-tailwind-black;
          @include styles.mixins-style-font-format(16px, 24px, 700);
          margin-bottom: 5px;
        }

         .icon-container {
            display: flex;
            flex-direction: row;
            align-items: center;

           .store__address {
              color: styles.$variables-tailwind-black;
              @include styles.mixins-style-font-format(16px, 24px, 400);
           }
         }
      }
    }
  }

  .order__summary-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    border-top: 1px solid rgba(222, 229, 224, 1) !important; 
    padding: 20px;
    padding-bottom: 0;

    .heading {
      color: styles.$variables-tailwind-black;
      @include styles.mixins-style-font-format(16px, 20px, 700);
    }

    .id__container {
      display: flex;
      flex-direction: row;
      align-items: center;

      .order__summary-id {
        color: styles.$variables-tailwind-black;
        @include styles.mixins-style-font-format(16px, 20px, 700);
  
      }
      span {
        color: styles.$variables-tailwind-black;
        @include styles.mixins-style-font-format(16px, 20px, 500);
        margin-right: 5px;
        margin-left: 5px;
      }
    }

  }

  .order_summary-billings {
    padding: 20px;
    padding-top: 0px;
  }
}

@media (min-width: 660px) {
  .order___status-mob {
    display: none;
  }
}
@media (max-width: 660px) {

  .order___status-mob {
    width: 100%;
    height: unset;
    border-top: 1px solid rgba(222, 229, 224, 1);
    margin-bottom: 20px;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .inprogress__timeline-container{
      margin-left: 0;
      height: unset;
    }
  }
  
}

@media (max-width: 440px) {
  .order__summary {
  
    .order__summary-heading
    {
      flex-direction: column;
      align-items: flex-start;
      .heading {
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: 660px) {
  .order__summary {
  
    .order__summary-table {
      display: none;
    }

    .order__summary-table__mobile {
      display: flex;
      flex-direction: column;
      padding: 20px;
    }

    .order_summary-billings {
      padding: 10px;
    }

    .order__summary-store {

      .print__order-btn {
        display: none;
      }
    }
  }
}


@media (max-width: 460px) {
  .order__summary {
    .order__summary-store {

      .store__details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    
        &-avatar {
          margin-bottom: 10px;
        }

        .details__container {
  
          .store__name {
            @include styles.mixins-style-font-format(14px, 24px, 700);
          }
  
           .icon-container {

             .store__address {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}