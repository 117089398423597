@use '../../styles';

.auth__page {
  width: 100vw;
  min-height: 100vh;
  @include styles.mixins-style-flex-column-center;

  .logo__wrap {
    width: 100%;
    padding: 10px;
    @include styles.mixins-style-flex-column-center;
    
    .logo__sub-wrap {
      max-width: 1440px;
      width: 96%;
   
      img {
        width: 180px;
      }
    }

  }
}