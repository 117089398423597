@use '../../../../../../styles';

.discount__price-comp {

  .discount__price-heading {
    @include styles.mixins-style-font-format(16px, 24px, 500);
    letter-spacing: 0px;
    text-align: left;
    color: styles.$variables-tailwind-black;
    margin-bottom: 10px;
  }

  .price__fields-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 286px;

    .price-radio {
      width: 50% !important;
    }

    .price-field {
      width: 144px;
    }
  }

  .original__price-container {
    display: flex;
    flex-direction: row;
    margin-top: 15px;

    .number-field {
      width: 100% !important;
      max-width: 200px !important;
      margin-right: 25px;
    }

    .discounted__value {
      color: rgba(15, 23, 42, 1);
      @include styles.mixins-style-font-format(16px, 24px, 400);
    }
  }

}

@media (max-width: 550px) {
  .discount__price-comp {

    .price__fields-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .original__price-container {
      display: flex;
      flex-direction: column;
    }
  }
}
