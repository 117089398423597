@use '../../../styles';

.ant-layout.layout-with-sidebar {
  &, .ant-layout {
    background: styles.$variables-light-gray-2;
    height: 100vh;
    width: 100%;
    overflow-y: hidden;
  }
  
  .content {
    height: calc(100vh - 72px);
    padding: 30px 40px 0;
    overflow: scroll;
  }
}
