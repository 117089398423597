@use '../../../../styles';

.custom-pagination {
  width: 100%;
  @include styles.mixins-style-flex-row-v-center;
  justify-content: space-between;
  gap: 6px;
  margin-top: 20px;
  padding: 0 20px 20px;

    &__heading {
      min-width: 115px;
      @include styles.mixins-style-font-format(14px, 20px, 500);
      color: styles.$variables-dark-grey;
      margin: 0;
    }

  .ant-pagination {
    @include styles.mixins-style-flex-row;
    width: calc(100% - 115px);

    .ant-pagination-options {
      order: -1;
      margin: 0 auto 0 0;

      .ant-select-selector {
        @include styles.mixins-style-width-height(65px, 32px);
        background: styles.$variables-white;
      }
    }

    .ant-pagination-item {
      @include styles.mixins-style-width-height(32px, 32px);

      a {
        @include styles.mixins-style-font-format(16px, 30px, 400);
        font-family: 'Mulish', sans-serif;
        color: styles.$variables-dark-grey;
      }

      &:hover {
         background: styles.$variables-tailwind-light-green-2;

        a {
          color: styles.$variables-dark-grey !important;
        }
      }
    }

    .ant-pagination-item-active{
      background: styles.$variables-green;
      border: none;

      a {
        color: styles.$variables-white !important;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0em;
      }

      &:hover {
        background: styles.$variables-green;
        border: none;

        a {
          color: styles.$variables-white !important;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0em;
        }
      }
    }

    .ant-pagination-item-ellipsis {
      color: styles.$variables-extended-dark-blue-stroke;

      &:active, &:focus {
        background: none;
      }
    }

    .ant-pagination-next, .ant-pagination-prev {

      .ant-pagination-item-link {
        svg {
          fill: styles.$variables-dark-grey;
        }

        &:hover {
          background: none;
        }

        &:disabled {
          svg {
            fill: styles.$variables-extended-dark-blue-stroke;
          }
        }
      }

    }
  }
}
