@use '../../../../../../../../styles';

.outlet_availability_form {
  .store__summary {
    margin: 2rem;
    .summary__heading { 
      color: styles.$variables-tailwind-black;
      @include styles.mixins-style-font-format(16px, 24px, 700);
      margin-bottom: 1rem;
    }
  
    .summary__details {
      display: flex;
      flex-direction: row;
  
      .summary__timeline {
        width: 50%;
      }
    }

    .summary__heading-note{
      margin-bottom: 1rem;
    }
  }
  
  .ant-btn-primary {
    max-width: 188px !important;
  }
}

@media (max-width: 786px) {
  .store__summary { 
    margin: 0;
    .summary__timeline {
      width: 100% !important;

      .timeline__container {
        max-width: none !important;

        &--data {
          @include styles.mixins-style-font-format(12px, 20px, 500);
        }
      }
    }

    .summary__details {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}