@use '../../../../../../../../styles';

.order__billing-detail {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid rgba(222, 229, 224, 1) !important;
  border-top: 0; 

  .price__info--container {
    width: 100%;
    max-width: 638px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .price__info-label {
      &:nth-child(2) 
      {
        text-align: right;
      }
    }
  }

  .price__info-label {
    color: styles.$variables-tailwind-black;
    @include styles.mixins-style-font-format(16px, 24px, 500);
  }

  .total__price-container {
    width: 100%;
    max-width: 638px;
    min-height: 52px;
    padding: 14px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: rgba(243, 245, 248, 1);

    .price__info-label {
      &:nth-child(2) 
      {
        font-weight: 600;
        text-align: right;
      }
    }
  }
}


@media (max-width: 660px) {
  .order__billing-detail {
    padding: 10px;
  
    .price__info-label {
      @include styles.mixins-style-font-format(14px, 24px, 500);
    }
  }
}