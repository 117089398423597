@use  '../../../../../../../../styles';

.ant-modal-wrap {

  .add__product-modal {
  
    .add__product-actions {

      .ant-form-item-required {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;

        &::before {
          margin-left: 4px;
          display: block !important;
        }
      }

      .ant-btn {
        max-width: 135px;
      }
    }

    &.ant-modal  {  
      width: 479px !important;  

      .ant-modal-content {
        border-radius: 20px;
        height: 467px !important;

        .ant-modal-header {

          .ant-modal-title {
            @include styles.mixins-style-font-format(16px, 24px, 500);
            color:  styles.$variables-tailwind-black;
            padding-bottom: 10px;
            border-bottom: 1px solid styles.$variables-tailwind-gray-5;
          }
        }

        .ant-modal-body {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
