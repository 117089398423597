@use '../../../../../../styles';

.order__detail-wrapper {
  width: 100%;
  max-width: 702px;
  min-height: 142px;
  border: 1px solid rgba(222, 229, 224, 1);
  border-radius: 8px;
  margin-bottom: 20px;

  .order__detail-heading {
    color: styles.$variables-tailwind-black;
    padding: 20px;
    @include styles.mixins-style-font-format(18px, 20px, 700);
    border-bottom: 1px solid rgba(222, 229, 224, 1);
  }

  .order__detail-wrapper__body {
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 660px) {
  .order__detail-wrapper {
    .order__detail-wrapper__body {
      padding: 0px;
    }
  }
}