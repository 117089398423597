@use '../../../../../../../../../styles';

.rejection__reason-form {
  padding-right: 20px;
  padding-left: 20px;

  .form__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;

    .form___header-icon {
      width: 30px;
      height: 30px;
      margin-inline-end: 10px;
      cursor: pointer;
    }
    .form__title {
      color: styles.$variables-tailwind-black;
      @include styles.mixins-style-font-format(16px, 20px, 700);
      .form___title-required {
        color: red;
      }
    }
  }

  .reject-btn {
    background: rgba(234, 67, 53, 1) !important;
    border-radius: 8px;
  }

  .cancel-btn {
    background: rgba(0, 200, 83, 1) !important;
    border-radius: 8px;
  }

  .form__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
}
