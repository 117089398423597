@use '../../styles';

.footer__container {
  width: 100vw;
  bottom: 0;
  position: static;

  .sub__container {
    background-color: styles.$variables-light-gray;
    @include styles.mixins-style-flex-column;
    align-items: center;
    width: 100%;
    padding: 0 2rem;
    
    .footer__links {
      padding: 10px;
      width: 100%;
      max-width: 1440px;
      max-height: 250px;
      @include styles.mixins-style-flex-column;
      justify-content: center;

      &-heading {
        @include styles.mixins-style-font-format(24px, normal, 600, uppercase);
        color: styles.$variables-black;
        margin-bottom: 20px;
      }
  
      .link {
        color: styles.$variables-gray;
        @include styles.mixins-style-font-format(16px, normal);
        text-decoration: none;
        margin-bottom: 8px;
      }
    }
  }
 


  .footer__rights {
    text-align: center;

    p {
      color: styles.$variables-gray;
      margin-bottom: 0;
      padding: 5px 0;
      font-family: "Jost", sans-serif!important;
      letter-spacing: 2px;
    }
  }
}