@use '../../../../styles';

.user__detail {

  width: 100%;
  height: 100%;
  margin-bottom: 20px;

  .save-button {
    width: 50%;
    max-width: 572px;
    margin: auto;
  }

  .disable_email {
    .input {

      .ant-input-affix-wrapper {
        background: none !important;
        border: none !important;
        cursor: default;

        .ant-input.ant-input-disabled {
          cursor: default !important;
        }
      }
    }
  }
  
  .input_row {
    .input {
      &:nth-child(2) {
        margin-left: 20px !important;
      }

      @media (max-width: 1024px) {
        &:nth-child(2) {
          margin-left: 0 !important;
        }
      }
    };
  }

  .button_row {
    width: 100%;
  }

  .order__tab {
    min-height: 360px;

    .no__orders {
      min-height: 360px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

.add__outlet-btn {
  @include styles.mixins-style-font-format(16px, 24px, 500);
  letter-spacing: 0px;
  color: styles.$variables-tailwind-green-2;
  background: styles.$variables-tailwind-gray-8 !important;
  border: none !important;

  &:hover, &:focus {
   color: styles.$variables-tailwind-green-2 !important;
  }

  .ant-btn {
    width: 151px;
    height: 38px;
    border-radius: 6px;
  }
}
