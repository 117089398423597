@use '../../../../../styles';

.sidebar-container {
  background: white;
  height: 100vh;
  width: 280px;
  @include styles.mixins-style-flex-column;

  .ant-layout-sider, .ant-layout-sider-dark {
    width: 180px !important;
    max-width: 180px !important;
    min-width: 180px !important;
    flex: 0 0 180px !important;
  }
  &__logo-container {
    @include styles.mixins-style-flex-row;
    align-items: center;
    padding: 15px;

    img {
      width: 180px;
      height: 50px;      
    }
  }
}

.sidebar {
  background-color: white;
  height: 100vh;

  &__menu.ant-menu {
    @include styles.mixins-style-border(solid, 1px, styles.$variables-light-gray-2, 0, (right));
    border: none;
    @include styles.mixins-style-flex-column;
    background-color: styles.$variables-white;
    align-items: center;
    color: styles.$variables-gray;
    @include styles.mixins-style-font-format(16px, normal, 600);
    height: calc(100vh - 160px);
    min-height: 600px;
    overflow-y: scroll;

    .ant-divider  {
      margin-top: 15px !important;
      margin-bottom: 15px !important;
      width: 80% !important;
      background: styles.$variables-tailwind-gray-5 !important;
      max-width: 200px !important;
      min-width: 80% !important;
    }
    
    .ant-menu-submenu {
      width: 100%;
      .sidebar-icon {
        @include styles.mixins-style-flex-center;
        @include styles.mixins-style-width-height(36px, 36px);
        padding: 6px;
        border-radius: 18px;

        svg {
          @include styles.mixins-style-width-height(24px, 24px);
        }
      }

      .ant-menu-sub {

        .ant-menu-item {
          padding-left: 48px !important;

          .ant-menu-item-active {
            background-color: styles.$variables-tailwind-gray-8 !important;
            color: styles.$variables-tailwind-green !important;
            border-right: 3px solid styles.$variables-tailwind-green !important;
          }
        }
      }
      .ant-menu-item-active {
        background-color: styles.$variables-tailwind-gray-8;
        color: styles.$variables-tailwind-green;
         border-right: 3px solid styles.$variables-tailwind-green;
        border-radius: 0 !important;
      }
    }
 
      .ant-menu-submenu-title {
        width: 100%;
        border-radius: 0 !important;
        margin: 0 !important;
        gap: 14px;

        .ant-menu-title-content {
          @include styles.mixins-style-font-format(14px, 24px, 500, capitalize);
          width: 140px;
        }
      }

    &::-webkit-scrollbar {
      width: 4px;
      display: none;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }
    
    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.1);
    }

    .ant-menu-item {
      height: 48px;
      min-height: 48px;
      padding: 0!important;
     
      gap: 14px;
      margin: 0;
      margin-bottom: 4px;

      &:not(:last-child) {
        margin: 0;
      }

      .ant-menu-title-content {
        @include styles.mixins-style-font-format(14px, 24px, 500, capitalize);
        width: 140px;
      }

      .sidebar-icon {
        @include styles.mixins-style-flex-center;
        @include styles.mixins-style-width-height(36px, 36px);
        padding: 6px;
        border-radius: 18px;

        svg {
          @include styles.mixins-style-width-height(24px, 24px);
        }
      }
    }

    .ant-menu-item-active {
      background-color: styles.$variables-tailwind-gray-8;
      color: styles.$variables-tailwind-green;
       border-right: 3px solid styles.$variables-tailwind-green;
      border-radius: 0 !important;
    }

    .ant-menu-item:after {
      border-right: none;
    }

    .ant-menu-item-active:hover {
      background-color: styles.$variables-tailwind-gray-8;
      color: styles.$variables-tailwind-green;
       border-right: 3px solid styles.$variables-tailwind-green;
      color: styles.$variables-white;
    }
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:focus {
    background-color: styles.$variables-tailwind-gray-8;
    color: styles.$variables-tailwind-green;
     border-right: 3px solid styles.$variables-tailwind-green;
    border-radius: 0 !important; 
    align-items: center;

    .sidebar-icon {
      background-color: none;
      color: styles.$variables-tailwind-green;
    }

    .ant-menu-title-content {
      font-weight: 700;
    }
  }

  &__menu-trigger.ant-btn {
    padding: 0 24px 0 0;
    @include styles.mixins-style-width-height(100%, 24px);
    @include styles.mixins-style-flex-center;
    box-shadow: none;

    &, &:active, &:hover, &:focus {
      background: transparent;
      border: none;
    }

    svg {
      @include styles.mixins-style-width-height(24px, 24px);
      color: styles.$variables-gray;
    }
  }

  .sidebar__menu.ant-menu {
    .ant-menu-item {
      width: 100%;
      padding-left: 24px !important;
    }
  }
}

@media (max-width: 1024px) {
  .sidebar-container {
    width: 100%;
  } 

  .sidebar {
    border-radius: 0;
  }
}
