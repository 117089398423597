.discount__range {
  display: flex;
  flex-direction: row;

  .input {
    width: 48%;

    &:nth-child(2) {
      margin-left: 10px;
    }
  }
}

@media (max-width: 576px) {
  .discount__range {
    flex-direction: column;
    align-items: left !important;
    .input {
      width: auto;
      max-width: 200px;
      
      &:nth-child(2) {
        margin-left: 0;
      }
    }
  }
}