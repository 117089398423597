@use  '../../../../../../../../../../styles';

.ant-modal-wrap {

  .store__block {

    .store__block-heading {
      @include styles.mixins-style-font-format(25px, 20px, 500);
      color: rgba(0, 0, 0, 1);
      text-align: center;
      margin-top: 20px;
      margin-bottom: 25px;
    }

    .confirmation__divider {
      width: 90px;
      height: 3px;
      border-radius: 2px;
      background: styles.$variables-tailwind-green;
      text-align: center;
      margin-bottom: 30px;
    }

    .store__block-text {
      @include styles.mixins-style-font-format(16px, 26px, 400);
      color: styles.$variables-tailwind-gray-11;
      text-align: center;
      margin-bottom: 20px;
    }

    .store__block-actions {
      .ant-btn {
        width: 133px;
        height: 48px;
        border-radius: 6px;
        background: styles.$variables-tailwind-gray-7;
        border: 1px solid styles.$variables-tailwind-gray-5;
        @include styles.mixins-style-font-format(16px, 21px, 500);
        color: rgba(0, 0, 0, 1);
  
        &:nth-child(2) {
          margin-left: 10px;
          background: rgba(198, 0, 0, 1);
          color: styles.$variables-white;
          border: none;
        }
      }
    }

    .store__activate-actions {
      .ant-btn {
        width: 133px;
        height: 48px;
        border-radius: 6px;
        background: styles.$variables-tailwind-gray-7;
        border: 1px solid styles.$variables-tailwind-gray-5;
        @include styles.mixins-style-font-format(16px, 21px, 500);
        color: rgba(0, 0, 0, 1);
  
        &:nth-child(2) {
          margin-left: 10px;
          background: rgb(47, 87, 56);
          color: styles.$variables-white;
          border: none;
        }
      }
    }
  
    &.ant-modal  {  
      width: 362px !important;  

      .ant-modal-content {
        border-radius: 20px;
        height: 255px !important;

        .ant-modal-body {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}

@media (max-width: 440px) {
  .ant-modal-wrap {

    .store__block {
      .store__block-actions {
        display: flex;
        flex-direction: column;

        .ant-btn {
          width: 133px;
          height: 48px;
          border-radius: 6px;
          background: styles.$variables-tailwind-gray-7;
          border: 1px solid styles.$variables-tailwind-gray-5;
          @include styles.mixins-style-font-format(16px, 21px, 500);
          color: rgba(0, 0, 0, 1);
    
          &:nth-child(2) {
            margin-left: 0;
            margin-top: 20px;
            background: rgba(198, 0, 0, 1);
            color: styles.$variables-white;
            border: none;
          }
        }
      }

      .store__activate-actions {
        .ant-btn {
          width: 133px;
          height: 48px;
          border-radius: 6px;
          background: styles.$variables-tailwind-gray-7;
          border: 1px solid styles.$variables-tailwind-gray-5;
          @include styles.mixins-style-font-format(16px, 21px, 500);
          color: rgba(0, 0, 0, 1);
    
          &:nth-child(2) {
            margin-left: 20px;
            background: rgb(47, 87, 56);
            color: styles.$variables-white;
            border: none;
          }
        }
      }

      &.ant-modal  {  
    
        .ant-modal-content {
          height: auto !important;
        }
      }
    }
  }
}