@use '../../../../../../styles';

.store__form {
  input[type=text]:focus {
    border: 1px solid styles.$variables-tailwind-gray-2 !important;
  }

  .auto__complete-container {
    svg {
      cursor: pointer;
    }
  }
  
  .auto__complete-input {
    @include styles.mixins-style-font-format(16px, 24px, 400);
    letter-spacing: 0px;
    text-align: left;
    color: styles.$variables-tailwind-black;
    height: 46px;
    border-radius: 6px;
    border: 1px solid styles.$variables-tailwind-gray !important;
    width: 100%;
  
    &::placeholder {
      color: styles.$variables-tailwind-gray;
    }
  
    &:focus {
      border: none !important;
      outline: none !important;
    }
    // &:focus, &:active {
    //   border: 1px solid styles.$variables-tailwind-gray-2 !important;
    //   outline: 0 !important;
    // }
  }
}
