@use '../../../../styles';

.store__details {

  .ant-tabs { 
  
    .ant-tabs-nav {
      background: styles.$variables-white !important;
      padding: 20px;
      margin-bottom: 0px !important;
    }

    .ant-tabs-content-holder {
      overflow: hidden;
    }
    
    .ant-tabs-nav-wrap {
      min-height: unset !important;

      .ant-tabs-nav-list {
        min-height: unset !important;
      }
    }
  } 
}