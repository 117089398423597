@use '../../../styles';

.account-delete__container {
  @include styles.mixins-style-width-height(100%, 100%);
  max-width: 420px;
  flex-grow: 1;
  @include styles.mixins-style-flex-column;
  justify-content: center;
  margin: 1rem;
  min-height: 45vh;
  margin-top: 8rem;
  margin-right: auto;
  margin-left: auto;
  
  .account-delete__form {

    &-heading {
      @include styles.mixins-style-font-format(24px !important, normal, 400, none, .03em);
      text-align: center;
      color: styles.$variables-gray;
      margin-bottom: 20px;
    }

    .account-delete__btn {
      @include styles.mixins-style-font-format(11px, 2px, normal, uppercase, 2px);
      background-color: styles.$variables-green;
      width: 100%;
      border-radius: 25px;
      position: relative;
      overflow: hidden;
      padding: 0;
    }
    
    .account-delete__btn::after {
      content: '';
      position: absolute;
      top: 0;
      left: 100%;
      width: 100%;
      height: 100%;
      background-color: transparent;
      transition: left 0.3s ease-in-out;
    }
    
    .account-delete__btn:hover::after {
      left: 0;
      background-color: styles.$variables-white;
    }
    
    .account-delete__btn .text {
      position: relative;
      z-index: 1;
      transition: color 0.3s ease-in-out;
    }
    
    .account-delete__btn:hover .text {
      color: styles.$variables-green;
    }

     &-options {
      display: flex;
      justify-content: end;
      gap: 0.5rem;
      margin-bottom: 1rem;

      p {
        color: grey;
        font-size: 14px !important;
      }

      a {
        color: styles.$variables-green;
      }

      .option {
        font-weight: 600;
        font-family: "Jost", sans-serif;
        text-decoration: none;
        margin-bottom: 0 !important;
      }
     }
  }
}
