$breakpoints: (
  'xs': 0,
  'sm': 576px,
  'm': 768px,
  'l': 992px,
  'xl': 1200px,
  'xxl': 1400px,
) !default;

// valid border sides
$valid-sides: top, left, bottom, right;

// valid directions for linear gradient
$valid-directions: to top, to left, to bottom, to right;

// valid types for radial gradient
$valid-radial-types: circle, ellipsis;

// valid border styles
$border-styles: dotted, dashed, hidden, none, solid, double, groove, ridge, inset, outset;

// valid text transform values
$text-transform-values: uppercase, lowercase, capitalize, none, initial, inherit;

// colors

$tailwind-black: rgba(33, 43, 54, 1);
$tailwind-black-2: rgba(45, 44, 44, 1);
$tailwind-green: rgba(28, 120, 52, 1);
$tailwind-green-2: rgba(3, 168, 78, 1);
$tailwind-green-3: #03543F;
$tailwind-green-4: rgba(3, 84, 63, 1);
$tailwind-light-green: #F3F4F6;
$tailwind-light-green-2: #DEF7EC;
$tailwind-gray: rgba(224, 224, 224, 1);
$tailwind-gray-2: #e5e7eb;
$tailwind-gray-3: rgba(204, 204, 206, 1);
$tailwind-gray-4: rgba(232, 244, 235, 1);
$tailwind-gray-5 :rgba(231, 231, 231, 1);
$tailwind-gray-6: rgba(250, 250, 250, 1);
$tailwind-gray-7: rgba(255, 255, 255, 1);
$tailwind-gray-8: rgba(233, 245, 236, 1);
$tailwind-gray-9: rgba(225, 239, 254, 1);
$tailwind-gray-10: rgba(222, 247, 236, 1);
$tailwind-gray-11: rgba(99, 115, 129, 1);
$tailwind-dark-gray: #4B5563;
$tailwind-dark-gray-2: #637381;
$tailwind-blue: rgba(30, 66, 159, 1);
$status-pending: rgba(254, 127, 56, 1);
$status-pending-light: rgba(255, 165, 114, 0.1);
$status-accepted: rgba(95, 157, 44, 1);
$status-accepted-light: rgba(95, 157, 44, 0.1);
$status-rejected: rgba(255, 108, 99, 1);
$status-rejected-light: rgba(255, 108, 99, 0.1);
$status-driver_requested: rgba(254, 127, 56, 1);
$status-waiting_pickup: rgba(254, 127, 56, 1);
$status-driver_requested-light: rgba(254, 127, 56, 0.1);
$status-waiting_pickup-light: rgba(254, 127, 56, 0.1);
$status-driver_assigned: rgba(0, 157, 138, 1);
$status-driver_assigned-light: rgba(0, 157, 138, 0.1);
$status-driver_arrived: rgba(121, 84, 226, 1);
$status-driver_arrived-light: rgba(121, 84, 226, 0.1);
$status-driver_in_route: rgba(57, 120, 255, 1);
$status-driver_in_route-light: rgba(57, 120, 255, 0.1);

$green: #1C7834;
$gray: #777777;
$light-gray: #F9F9F9;
$light-gray-2: #f5f6f9;
$white: #ffffff;
$black: #000000;

$light-blue-1: #eff6ff;
$light-blue-2: #e2edff;
$light-blue-3: #cde2ff;
$light-blue-4: #a2c4f3;
$light-blue-5: #93b7e9;
$light-blue-6: #5490e4;
$light-blue-7: #a6bbdd;
$dark-blue-1: #0065f3;
$dark-blue-2: #116df3;
$red: #AA4344;
$light-red: #FAA0A0;

$primary-blue: #0062ED;
$secondary-blue: #0058D4;
$dark-blue: #0042A1;
$primary-light-blue: #EBF3FF;
$dark-grey: #2F4051;
$primary-grey: #767676;
$secondary-grey: #C5C5C5;
$tertiary-grey: #CFD7E0;
$light-grey: #D4D4D4;

$extended-green: #36806E;
$extended-green-stroke: #C4F0C2;
$extended-purple: #6C4BB2;
$extended-purple-stroke: #DCCEFF;
$extended-dark-blue: #4B74B2;
$extended-dark-blue-stroke: #CED9FF;
$extended-teal: #4B86B3;
$extended-teal-stroke: #CEEAFF;
$extended-orange: #B2834B;
$extended-orange-stroke: #FFE8CE;
$extended-red: #B24B4B;
$extended-red-stroke: #FFCECE;
$extended-light-blue: #EAF2FE;
$extended-active-blue: #D9E9FF;
$extended-light-blue-2: #DBEAFF;


$primary-info: #0172CB;
$secondary-info: #C7E3FF;
$primary-warning: #DF7B00;
$secondary-warning: #FFDEC7;
$primary-success: #28A138;
$secondary-success: #D9FFC7;
$primary-error: #DA1E28;
$secondary-error: #FFC7C7;

// shadow
$box-shadow: 0 8px 15px 2px rgba(47, 64, 81, 0.07);
$vertical-box-shadow: 0 24px 24px rgba(47, 64, 81, 0.07);
$spread-shadow: 0px 0px 10px 3px rgba(47, 64, 81, 0.07);
