@use 'sass:list';
@use '../variables';

@mixin linear-gradient-background($rgba, $color, $percentage) {
  background: linear-gradient(180deg, $rgba 0%, $color $percentage), variables.$white;
}

@mixin green-gradient {
  @include linear-gradient-background(rgba(234, 254, 249, 0.4), #EAFEF9, 72.01%);
}

@mixin purple-gradient {
  @include linear-gradient-background(rgba(240, 234, 254, 0.4), #F0EAFE, 70.42%);
}

@mixin blue-gradient {
  @include linear-gradient-background(rgba(234, 242, 254, 0.4), #EAF2FE, 70.31%);
}

@mixin teal-gradient {
  @include linear-gradient-background(rgba(234, 245, 254, 0.4), #EAF5FE, 70.31%);
}

@mixin orange-gradient {
  @include linear-gradient-background(rgba(254, 245, 234, 0.4), #FEF5EA, 70.31%);
}

@mixin red-gradient {
  @include linear-gradient-background(rgba(254, 234, 234, 0.4), #FEEAEA, 70.31%);
}
