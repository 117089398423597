@use "../../../../styles";

.table__header {
  background-color: styles.$variables-white;
  width: 100%;
  height: 76px;
  border-radius: 8px;
  border: 1px;
  margin-bottom: 20px;
  padding: 15px 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1 auto;

  .input {
    padding-right: 0.5rem;
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }

  &-options {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;

    .header__btn {
      width: 115px;
      height: 44px;
      border-radius: 6px;
      border: 1px solid styles.$variables-tailwind-gray-5;
      background-color: styles.$variables-white;
      color: styles.$variables-black;
      margin-left: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      box-shadow: none !important;
      
      svg {
        width: 19;
        height: 17;
      }
  
      &:hover {
        border: 1px solid styles.$variables-tailwind-gray-5;
        background-color: styles.$variables-white;
        color: styles.$variables-black;
    
      }
    }
  }
}
