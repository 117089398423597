@use '../../../../../../styles';

.delivery__service-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  border: 1px solid rgba(222, 229, 224, 1);
  border-radius: 8px 8px 0 0;
  border-bottom: 0;

  .delivery__service-container {
    margin-left: 10px;

    .delivery__heading {
      color: styles.$variables-tailwind-black;
      @include styles.mixins-style-font-format(16px, 24px, 700);
    }

    .delivery__service {
      color: styles.$variables-tailwind-black;
      @include styles.mixins-style-font-format(16px, 24px, 500);
    }
  }

  .order__summary-pickup-icon {
    font-size: 2.5rem;
  }

  .order__summary-pickup-heading {
    display: flex;
    flex-direction: column;
    margin-inline-start: 10px;
    .order__summary-pickup-heading-title {
      font-size: 1.333rem;
    }
    .order__summary-pickup-heading-expiry {
      border: 1px solid orange;
      border-radius: 5px;
      padding: 10px;
      background: rgba(255, 165, 0, 0.1);
      color: rgba(131, 134, 136, 1);
      @include styles.mixins-style-font-format(12px, 15px, 400);
      &-date {
        color: rgba(131, 134, 136, 1);
        font-weight: 500;
      }
    }
  }
}
