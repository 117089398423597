@use '../../../../../../../../../../styles';

.waiting__timeline {
  width: 100%;
  display: flex;
  flex-direction: row;

  .timeline-icon_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(25px);

    .timeline__connector {
      height: 82px;
      width: 1px;
      border: 2px dashed rgba(183, 186, 181, 1);
    }
  }

  &-container {
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 315px;
    height: 77px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid rgba(222, 229, 224, 1);
    background: white;

    .title {
      color: styles.$variables-tailwind-black;
      @include styles.mixins-style-font-format(16px, 20px, 400);
    }
  }
}