@use '../../../../../../../../../../styles';

.completed__timeline {
  width: 100%;
  display: flex;
  flex-direction: row;

  .timeline-icon_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(25px);

    .timeline__connector {
      height: 100px;
      width: 1px;
      border: 2px solid styles.$variables-status-accepted;
    }
  }

  &-container {
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 315px;
    height: 77px;
    padding: 16px;
    border-radius: 8px;
    background: rgba(243, 246, 248, 1);

    .title__container {
      display: flex;
      flex-direction: column;

      &-title {
        color: styles.$variables-tailwind-black;
        @include styles.mixins-style-font-format(16px, 20px, 400);
        margin-bottom: 5px;
      }

      &-date {
        color: rgba(131, 134, 136, 1);
        @include styles.mixins-style-font-format(12px, 15px, 400);
      }
    }

  }
}