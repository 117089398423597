@use '../../../../styles';

.edit__store {
  max-width: 796px;
  padding-right: 30px;
  
  .categories__container  {
    
    .input .ant-select {
      text-align: center;
      min-height: 46px;
      height: auto;

    .ant-select-selection-overflow {
      min-height: 40px;
    }
    
    }
  }

  .brands__container  {
    
    .input .ant-select {
      text-align: center;
      min-height: 46px;
      height: auto;

    .ant-select-selection-overflow {
      min-height: 40px;
    }
    
    }
  }

  .min__orders {
    max-width: 200px;
    margin-right: 25px;
  }

  .form__heading {
    color: styles.$variables-tailwind-black;
    @include styles.mixins-style-font-format(16px, 24px, 700);
    margin-bottom: 30px;
  }

  .profile_image {
      .ant-upload-list {
        max-width: 175px !important;
      }
  }

  .ant-upload-wrapper.ant-upload-picture-card-wrapper.cover_image-field {
    .ant-upload-list {
      width: 373px !important;
    }
  }

  .ant-btn {
    max-width: 188px;
  }
}

@media (max-width: 1110px) {
  .edit__store {
    .images__container{
      display: flex;
      flex-direction: column;
    }
  }
}

@media (max-width: 700px) {
  .edit__store {
    .categories__container  {
      display: flex;
      flex-direction: column;

      .ant-col {
        max-width: 100%;
      }
    }
    .brands__container  {
      display: flex;
      flex-direction: column;

      .ant-col {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 600px) {
  .edit__store {
    padding-right: 0px;
    .fields__container{
      display: flex;
      flex-direction: column;

      .ant-col {
        max-width: 100% !important;
      }
    }

    .ant-btn {
      max-width: 100%;
    }
  }
}


@media (max-width: 490px) {
  .edit__store {

    .ant-col {
      max-width: 100% !important;
    } 

    .profile_image {
      .ant-upload-list {
        max-width: unset !important;
      }
  }

  .ant-upload-wrapper.ant-upload-picture-card-wrapper.cover_image-field {
    .ant-upload-list {
      width: unset !important;
    }
  }
  }
}
