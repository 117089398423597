@use '../../../../../../../../../../styles';

.inprogress__timeline {
  width: 100%;
  display: flex;
  flex-direction: row;

  .timeline-icon_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(25px);

    .timeline__connector {
      height: 120px;
      width: 1px;
      border: 2px solid styles.$variables-status-accepted;
    }
  }

  &-container {
    width: 100%;
    max-width: 315px;
    height: 117px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid rgba(95, 157, 44, 1);
    background: rgb(245,251,241);

    .title__container {
      display: flex;
      flex-direction: column;

      &-title {
        color: styles.$variables-tailwind-black;
        @include styles.mixins-style-font-format(16px, 20px, 600);
        margin-bottom: 5px;
      }

      &-date {
        color: rgba(131, 134, 136, 1);
        @include styles.mixins-style-font-format(12px, 15px, 400);
      }
    }

    .inprogress__timeline-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;

      .request-btn {
        width: 100%;
        max-width: 134px;
        height: 32px;
        border-radius: 8px;
        background: styles.$variables-status-accepted;
        color: styles.$variables-tailwind-gray-7;
        border: none !important;
        margin-right: 5px;
        @include styles.mixins-style-font-format(14px, 24px, 500);
      }

    .reject-btn { 
      width: 100%;
      max-width: 134px;
      height: 32px;
      border-radius: 8px;
      background: rgba(234, 67, 53, 1);
      color: styles.$variables-tailwind-gray-7;
      border: none !important;
      @include styles.mixins-style-font-format(14px, 24px, 500);
    }

    }
  }
}