@use '../../../../../../styles';

.order__detail-data__column {

  display: flex;
  flex-direction: column;

  .data__column-label {
    width: 100%;
    color: styles.$variables-tailwind-black;
    @include styles.mixins-style-font-format(16px, 24px, 600);
    margin-bottom: 5px;
    max-width: 204px;
  }

  .data__column-value {
    display: flex;
    flex-direction: column;
    white-space: normal;
    word-break: break-word;
    width: 100%;
    max-width: 204px;
    color: styles.$variables-tailwind-black;
    @include styles.mixins-style-font-format(16px, 24px, 400);
  }
}

@media (max-width: 660px) {
  .order__detail-data__column  {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100% !important;
    padding-bottom: 10px;

    .data__column-label {
      padding: 10px;
      font-size: 14px;
    }

    .data__column-value {
      text-align: right;
      padding: 10px;
      font-size: 14px;
    }
  }
}

@media (max-width: 440px) {
  .order__detail-data__column  {
    .data__column-value {
      max-width: 179px;
    }
  }
}