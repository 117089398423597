@forward 'variables' as variables-*;
@forward 'mixins' as mixins-*;

@use 'variables';
@use 'mixins';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Inter'!important;
}

[ant-click-animating-without-extra-node='true']::after {
  display: none;
}

.ant-dropdown-menu.dropdown-button {
  .ant-btn.ant-btn-default {
    background: transparent;
    padding: 0;
    height: fit-content;
  }
}

.table-email-column {
  @include mixins.style-font-format(14px, normal, 500, lowercase);
  @include mixins.style-flex-row-v-center;
}

// Antd Button classes
.ant-btn {
  &-primary {
    width: 100%;
    height: 48px;
    padding: 14px 40px 14px 40px;
    border-radius: 5px;
    background: variables.$tailwind-green !important;
    color: variables.$tailwind-gray-7 !important;
    @include mixins.style-font-format(16px, 20px, 600);
    letter-spacing: 0px;
    text-align: center;    
  }

  &-text {
    width: 100%;
    height: 48px;
    padding: 14px 40px 14px 40px;
    border-radius: 5px;
    background: variables.$tailwind-gray-7!important;
    color: variables.$tailwind-black!important;
    @include mixins.style-font-format(16px, 20px, 600);
    letter-spacing: 0px;
    text-align: center;  
    border: 1px solid rgba(223, 223, 223, 1);
  }

  &.tab-button {
    &-lg, &-sm, &-md {
      @include mixins.style-flex-center;
      flex: 1 1;
      gap: 4px;

      &:not(:first-child):not(:last-child) {
        @include mixins.style-border(solid, 1px, variables.$primary-blue, 0, (top, left, bottom));
        border-right: none;
      }

      &:first-child {
        @include mixins.style-border(solid, 1px, variables.$primary-blue, 8px 0 0 8px, (top, left, bottom));
        border-right: none;
      }

      &:last-child {
        @include mixins.style-border(solid, 1px, variables.$primary-blue, 0 8px 8px 0);
      }

      &:not(:hover):not(:active):not(:disabled):not(.tab-button-active) {
        background: variables.$white;
      }

      &, &:hover {
        color: variables.$primary-blue;
      }

      &:hover {
        background: variables.$extended-active-blue;
      }

      &:active,
      &.tab-button-active {
        color: variables.$white;
        font-weight: 700;
        background: variables.$primary-blue;
        border-color: variables.$primary-blue;
      }
    }

    &-lg {
      @include mixins.style-font-format(14px, 20px, 400);
      padding: 12px 16px;
      height: 40px
    }

    &-md {
      padding: 8px 16px;
      height: 32px;
    }

    &-sm, &-md {
      @include mixins.style-font-format(12px, 16px, 500);
    }

    &-sm {
      padding: 4px 16px;
      height: 24px;
    }
  }

  .PhoneInput {
    font-family: "Inter" !important;
    font-size: 16px !important; 
    font-weight: 400;
    line-height: 24px !important;
    letter-spacing: 0px;
    text-align: left;
    color: variables.$tailwind-black!important;
    height: 46px !important;
    border-radius: 6px !important;
    border: 1px solid variables.$tailwind-gray !important;

    .PhoneInputCountry {
      display: none !important;
    }
  }

  &.sub-tab {
    @include mixins.style-border(solid, 1px, variables.$tertiary-grey, 0, bottom);
    color: variables.$dark-grey;
    padding: 12px 16px;
    background: transparent;
    box-shadow: none;
    height: 40px;

    .tab-text {
      @include mixins.style-capitalize-first-word-only;
      @include mixins.style-font-format(14px, 20px, 400);
      margin: 0;
    }

    &.active-sub-tab {
      border-bottom-color: variables.$primary-blue;
      color: variables.$primary-blue;
      font-weight: 700;
    }
  }
}

.dot {
  @include mixins.style-width-height(2px, 2px);
  background: variables.$dark-grey;
  border-radius: 50%;
}

.ant-btn-link {
  &.dark-blue-link,
  &.add-new-btn {
    color: variables.$dark-blue-2;
    font-weight: bold;
  }

  &.add-new-btn {
    @include mixins.style-flex-row-v-center;
    gap: 10px;
    text-transform: uppercase;

    &:hover {
      color: variables.$light-blue-6;
    }
  }
}

// Animations
@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

// for centering antd notification
.ant-notification {
  margin-right: 0;
  right: 50% !important;
  transform: translateX(50%);
}

.ant-popover {
  z-index: 15;
}

.spinner {
  @include mixins.style-width-height(100%, 100%);
  @include mixins.style-flex-center;
}

svg {
  stroke-width: 0;
}

// Dropdown menu
.ant-dropdown-menu,
.ant-select-dropdown {
  border-radius: 20px;
  overflow: hidden;
}

.ant-dropdown-menu-item-active {
  background-color: variables.$extended-light-blue !important;
  color: variables.$dark-grey !important;
}

.ant-select-item-option {
  font-family: "Inter" !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0px !important;
  color: variables.$tailwind-black!important;
  height: 100% !important;
  border: none !important;
  align-items: center;
}

.ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected,
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color:  variables.$tailwind-gray-8 !important;
  color: variables.$tailwind-green-2 !important;
}

// Tags
.tag {
  &, &-sm {
    &--blue,
    &--teal,
    &--orange,
    &--green,
    &--purple {
      padding: 6px 12px;
      @include mixins.style-font-format(14px, 20px, 500, capitalize);
    }

    &--blue {
      @include mixins.gradients-blue-gradient;
      @include mixins.style-border(solid, 1px, variables.$extended-dark-blue-stroke, 20px);
      color: variables.$extended-dark-blue;
    }

    &--green {
      @include mixins.gradients-green-gradient;
      @include mixins.style-border(solid, 1px, variables.$extended-green-stroke, 20px);
      color: variables.$extended-green;
    }

    &--purple {
      @include mixins.gradients-purple-gradient;
      @include mixins.style-border(solid, 1px, variables.$extended-purple-stroke, 20px);
      color: variables.$extended-purple;
    }

    &--orange {
      @include mixins.gradients-orange-gradient;
      @include mixins.style-border(solid, 1px, variables.$extended-orange-stroke, 20px);
      color: variables.$extended-orange;
    }

    &--teal {
      @include mixins.gradients-teal-gradient;
      @include mixins.style-border(solid, 1px, variables.$extended-teal-stroke, 20px);
      color: variables.$extended-teal;
    }
  }

  &-sm {
    &--blue,
    &--teal,
    &--orange,
    &--green,
    &--purple {
      border-radius: 16px;
      padding: 3px 12px;
    }
  }
}

// Item validation error jumping fix
.ant-form-item-explain-error {
    position: absolute;
}


// Tabs
.ant-tabs .ant-tabs-ink-bar  {
  background: none!important;
}

.ant-tabs {

  .ant-tabs-nav {
    padding-bottom: 10px;
    
    @media (max-width: 680px) {
      margin-right: 10px !important;
    }
  }
.ant-tabs-nav {
  @media (max-width: 680px) {
    display: flex;
    flex-direction: column;
    align-items: start !important;

  }
}

  .ant-tabs-nav-wrap {
    @include mixins.style-width-height(100% !important, auto);
    min-height: 80px !important;

    .ant-tabs-nav-list {
      @include mixins.style-width-height(100%!important, auto);
      min-height: 80px !important;
      align-items: center !important;
      transform: none !important;

      @media (max-width: 680px) {
        display: flex;
        flex-direction: column;
        align-items: start !important;

      }

      .ant-tabs-tab {
        @include mixins.style-flex-center;
        min-width: 123px;
        height: 40px;
        padding: 8px 20px 8px 20px;
        border-radius: 4px;
        margin-left: 10px !important;
        @include mixins.style-font-format(16px, 24px, 500);
        letter-spacing: 0px;
        text-align: center;
        border: 1px solid variables.$tailwind-gray-5;
        background: variables.$tailwind-gray-6;

        @media (max-width: 680px) {
          margin-bottom: 10px;
          margin-left: 0px !important;
          width: 100% !important;
        }

        &:first-child {
          margin-left: 0 !important;
        }

        .ant-tabs-tab-btn {
          color: variables.$tailwind-black!important;

          .tab__counter--container {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 16px;
         
            .tab__counter {
              margin-left: 5px;
              color: variables.$white!important;
              width: 20px !important;
              height: 20px !important;
              border-radius: 25px !important;
              font-size: 0.5rem;
              font-weight: 500;
              letter-spacing: 0px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              background: rgba(124, 130, 143, 1)!important;
            }
          }
        }

        &.ant-tabs-tab-active {
          background-color: variables.$tailwind-green !important;
          .tab__counter--container {
         
            .tab__counter {

              margin-left: 5px;
              color: variables.$tailwind-green !important;
              background: variables.$white!important;
            }
          }
          .ant-tabs-tab-btn {
            color: variables.$tailwind-gray-7 !important;
          }
        }
      }
    }
  }
}

.table__address {
  color:variables.$tailwind-dark-gray-2;
  @include mixins.style-font-format(14px, 24px, 400);
  max-width: 206px;
  white-space: nowrap;
  overflow: hidden;   
  text-overflow: ellipsis;  

}

.order__summary-avatar {
  min-width: 54px;
  min-height: 54px;
  border-radius: 6px;
  margin-right: 10px;
}

.table__item-avatar {
  background: rgba(15, 23, 42, 1) !important;
  color:variables.$white !important;
  @include mixins.style-font-format(15px, 18px, 600);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.table__content {
  color:variables.$tailwind-dark-gray-2;
  @include mixins.style-font-format(14px, 24px, 400);
}

.table__email {
  color:variables.$tailwind-dark-gray-2;
  @include mixins.style-font-format(14px, 24px, 400, lowercase);
}

.table__user-name {
  color:variables.$green;
  @include mixins.style-font-format(14px, 24px, 500);
  cursor: pointer;
}

.table__icon {

  display: flex;
  flex-direction: row;
  align-items: center;

  &--avatar {
    min-width: 35px;
    max-width: 35px;
    min-height: 35px;      
    max-height: 35px;      
    border-radius: 10px;
    margin-right: 20px;
  }
}

.table__status {

  &-active {
    width: auto;
    height: 24px;
    padding: 2px 10px 2px 10px;
    border-radius: 10px;
    background-color:variables.$tailwind-light-green-2;
    color:variables.$tailwind-green-3;
    @include mixins.style-font-format(12px, 18px, 400, capitalize);
    text-align: center;
    max-width: 76px !important;
  }

  &-inactive {
    width: auto;
    height: 24px;
    padding: 2px 10px 2px 10px;
    border-radius: 10px;
    background-color:variables.$tailwind-light-green;
    color:variables.$tailwind-dark-gray;
    @include mixins.style-font-format(12px, 18px, 400, capitalize);
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    max-width: 76px !important;
  }
}

.arabic__input {
  direction: rtl !important;
  text-align: right !important;
}