@use '../../../../styles';

.add__outlets {
  min-height: 200px;
  @include styles.mixins-style-flex-column;

  &-content {
    max-width: 572px;
    position: relative;
  }
}

.add__outlets-button {
  margin-top: 5rem;
}
