@use '../../../../../styles';

.navbar__container {
  @include styles.mixins-style-width-height(100%, auto);
  background-color: styles.$variables-white;
  @include styles.mixins-style-flex-row-end-center;
  padding: 15px;

  &-logo {
    height: 48px;
    border: 1px solid black;
    border-radius: 50%;
    overflow: hidden;
    padding: 5px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}