@use '../../../../../../../../styles';

.order__mobile-detail {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(222, 229, 224, 1);
  padding-bottom: 25px; 
  margin-bottom: 10px; 

  .order__mobile-container {
    .order__mobile-store__name {
      color: styles.$variables-tailwind-black;
      @include styles.mixins-style-font-format(16px, 24px, 500);
    }
    
    .order__mobile-unit__price {
      color: styles.$variables-tailwind-black;
      @include styles.mixins-style-font-format(14px, 22px, 400);
    }

    .order__mobile-amount {
      margin-top: 8px;
      color: styles.$variables-tailwind-green;
      @include styles.mixins-style-font-format(16px, 24px, 600);
    }
  }
}