@use "../../../../../../styles";

.permission__manager {
  margin-bottom: 24px;

  .label {
    @include styles.mixins-style-font-format(16px, 24px, 500);
    letter-spacing: 0px;
    color: styles.$variables-tailwind-black;
    margin-bottom: 12px !important;
  }

  max-width: 996px;

  .ant-collapse {
    .ant-collapse-header {
      padding: 24px 0;
      .ant-collapse-header-text {
        @include styles.mixins-style-font-format(16px, 24px, 500);
        letter-spacing: 0px;
        text-align: left;
        color: styles.$variables-tailwind-black;
      }
    }
    .ant-collapse-content-box {
      padding-left: 0;
      padding-right: 0;

      .input {
        width: auto !important;
      }
    }
  }

  .ant-checkbox-input {
    width: 24px;
    height: 24px;
    .ant-checkbox-inner {
      background-color: styles.$variables-tailwind-green !important;
      border-color: styles.$variables-tailwind-green !important;

      &:hover, &:focus {
        background-color: styles.$variables-tailwind-green !important;
      }
    }
  }
}