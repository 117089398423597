@use '../../../../../../styles';

.delivery__details {
  display: flex;
  padding: 20px;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  border: 1px solid rgba(222, 229, 224, 1);
  height: auto;

  .delivery__heading {
    color: styles.$variables-tailwind-black;
    @include styles.mixins-style-font-format(20px, 24px, 700);
    margin-bottom: 20px;
  }
}