@use "../../styles";

.wrapper__container {
  width: 100%;
  height: auto;
  padding: 20px;
  @include styles.mixins-style-border(solid, 1px, styles.$variables-tailwind-gray-5);
  background: styles.$variables-white;
  border-radius: 6px;
  margin-bottom: 20px;
}