@use '../../styles';

.breadcrumb-container {
  align-items: center;

  .ant-breadcrumb  {

    .ant-breadcrumb-link {
      span {
        margin-left: 0 !important;
      }
    }
  }

  .title {
    color: styles.$variables-tailwind-black;
    @include styles.mixins-style-font-format(24px, normal, 600, capitalize);
    @include styles.mixins-style-flex-row;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;

    &-content {
      margin-left: 20px;
    }

    &:hover, &:focus {
      background: none !important;
    }
  }
}
