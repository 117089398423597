@use '../../../../styles';

.order__list {
  max-width: 1265px;

  .ant-tabs { 
  
    .ant-tabs-nav {
      background: styles.$variables-white !important;
      padding: 20px;
      margin-bottom: 0px !important;
    }

    .ant-tabs-content-holder {
      overflow-x: scroll;
    }
    
    .ant-tabs-nav-wrap {
      min-height: unset !important;

      .ant-tabs-nav-list {
        min-height: unset !important;
      }
    }
  }

  .order__tables {
    margin-top: 15px;
    width: 100%;
    max-width: 1265px;
    margin-bottom: 40px;
  }
}


.order__content--container {
  display: flex;
  flex-direction: column;   
}

.order__table--content {
  color:rgba(0, 0, 0, 1);
  @include styles.mixins-style-font-format(14px, 24px, 500);
}

.order__table--address {
  display: inline-block;
  max-width: 205px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: styles.$variables-tailwind-dark-gray-2;
  @include styles.mixins-style-font-format(14px, 24px, 400);
}

.order__table--address-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .order__table--address {
    margin-left: 8px;
  }
}

@media (max-width: 1359px) {
  .order__list {
    .table-body {
      display: none !important;
    }
  }
}