@use '../../../../../../../../../../styles';

.rejected__timeline {
  width: 100%;
  display: flex;
  flex-direction: row;

  .timeline-icon_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(25px);
  }

  &-container {
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 315px;
    height: 77px;
    padding: 16px;
    border-radius: 8px;
    background: rgba(255, 239, 238, 1);
    border: 1px solid rgba(234, 67, 53, 1);

    .title__container {
      display: flex;
      flex-direction: column;

      &-title {
        color: styles.$variables-tailwind-black;
        @include styles.mixins-style-font-format(16px, 20px, 600);
        margin-bottom: 5px;
      }

      &-date {
        color: rgba(131, 134, 136, 1);
        @include styles.mixins-style-font-format(12px, 15px, 400);
      }
    }

  }
}

.rejected__reason {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .rejection__heading {
    color: styles.$variables-tailwind-black;
    @include styles.mixins-style-font-format(16px, 20px, 700);
  }
  .rejection__reason {
    color: styles.$variables-tailwind-black;
    @include styles.mixins-style-font-format(16px, 24px, 400);
  }
}