@use '../../../../styles';

.user-profile-view-container {
  padding: 2rem 4rem;
  max-width: 75vw;

  .profile-overview {
    gap: 16px;
    padding: 16px;
    width: 100%;
  
    &__left-content-container {
      @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-light-blue-2, 16px);
      @include styles.mixins-style-flex-column;
      background: styles.$variables-white;
      box-shadow: styles.$variables-vertical-box-shadow;
      gap: 8px;
      margin-right: 2rem;
      height: 300px;
      min-width: 240px;
  
      .profile-overview {
        &__image-container {
          @include styles.mixins-style-width-height(100%, 80%);
          border-radius: 8px;
          overflow: hidden;
  
          .profile-overview__image {
            @include styles.mixins-style-width-height(100%, 100%);
            object-fit: contain;
  
            &.cover-image {
              object-fit: cover;
            }
          }
  
          .profile-overview__no-image-container {
            @include styles.mixins-style-flex-center;
            background-color: styles.$variables-extended-light-blue;
            border-radius: 8px;
            height: 100%;
  
            svg {
              @include styles.mixins-style-width-height(32px, 32px);
              color: styles.$variables-dark-grey;
            }
          }
        }
  
        &__left-content-item {
          @include styles.mixins-style-flex-column;
          gap: 8px;
          padding-top: 0.5rem;
        }
  
        &__heading {
          @include styles.mixins-style-font-format(18px, 22px, 700, capitalize);
          color: styles.$variables-dark-grey;
          margin: 0;
          padding: 0 1rem;
        }
  
      }
    }
  
    &__right-content {
      @include styles.mixins-style-flex-column;
      width: 100%;
      gap: 8px;
  
      &-container {
        @include styles.mixins-style-flex-row;
        flex-wrap: wrap;
        flex: 0 1 100%;
        gap: 8px;
      }
    }
  
    .profile-overview__right-content-item {
      @include styles.mixins-style-flex-column;
      flex: 1 1 calc(50% - 8px);
  
      &:not(:first-child), &:not(:nth-child(2)) {
        margin-top: 8px;
      }
  
      &--label, &--value {
        color: styles.$variables-dark-grey;
      }
  
      &--label {
        @include styles.mixins-style-font-format(12px, 20px, 400, capitalize);
      }
  
      &--value {
        @include styles.mixins-style-font-format(14px, 20px, 700);
      }
    }
  }
}
