@use '../../../../styles';

.edit__product {
  max-width: 616px;
  padding-right: 30px;
  
  .categories__container  {
    
    .input .ant-select {
      text-align: center;
      min-height: 46px;
      height: auto;
      width: 100% !important;
      max-width: 380px !important;

    .ant-select-selection-overflow {
      min-height: 40px;
    }
    }
  
    .number-field {
      width: 200px !important;
      margin-right: 25px;
    }
  }

  .date__container  {


    .input, .ant-picker {
      width: 100% !important;
      max-width: 360px !important;
    }
  
    .number-field {
      width: 100% !important;
      max-width: 200px !important;
      margin-right: 25px;
    }
  }

  .ant-btn {
    max-width: 188px;
  }
}


@media (max-width: 736px) {
  .edit__product {
    .categories__container  {
      display: flex;
      flex-direction: column;

      .number-field {
        margin-right: 0;
      }

      .ant-col {
        max-width: 100%;
      }
    }
  }
}


@media (max-width: 490px) {
  .edit__product {
    .categories__container  {
      .number-field {
        width: 100% !important;
      }
    }
    padding-right: 0;
  }
}
